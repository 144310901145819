<template>
  <div class="mr-3">
    <v-row>
      <v-col class="">
        <switchGroup
          type="switchGroup"
          :fieldAttrInput="fieldAttrInput"
          :fieldAttributes="{
            name: 'Test Direction',
            fields: templateContent.fields.uplinkDownlinkSwitch.fields,
          }"
          :value="valueLine[field]"
          v-on="$listeners"
          field="uplinkDownlinkSwitch"
          :templateContent="templateContent"
          :valueLine="valueLine"
          :disabled="fieldAttrInput.disabled"
          id="test_direction_1"
        ></switchGroup>
      </v-col>
      <!--      <v-col cols="1" class="mt-6 mr-n3">
                <associatedOption
                        :fieldAttributes="{ name: 'Test Type', associatedOption: tType }"
                        type="select"
                        :fieldAttrInput="{ class: 'required' }"
                        v-model="timeVol"
                        :templateContent="result"
                        :isValid="isValid"
                         field="timeVol"
                ></associatedOption>
            </v-col>-->
      <v-col cols="2" class="mt-6 mr-n3">
        <defaultFieldTypes
          :templateContent="result"
          field="duration"
          :fieldAttributes="{
            ...fieldAttributes,
            fieldAttrInput: {},
            type: type,
            name: name,
          }"
          v-model="duration"
        ></defaultFieldTypes>
      </v-col>
      <v-col class="mt-6">
        <fieldsRows
          :templateContent="templateContentRow"
          :valueLine="valueLine"
          :isValid="isValid"
          v-on="$listeners"
        ></fieldsRows>
      </v-col>
    </v-row>
  </div>
</template>
<script>
//import associatedOption from "@/commonComponents/associatedOption.vue";
import switchGroup from "@/commonComponents/switchGroup.vue";
import { getfieldAttrInput } from "@/js/helper.js";
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";

export default {
  components: {
    // associatedOption,
    switchGroup,
    fieldsRows,
    defaultFieldTypes,
  },

  props: ["field", "fieldAttributes", "result", "valueLine", "isValid"],

  data() {
    return {
      tType: ["time", "volume"],
    };
  },
  computed: {
    /*  timeVol: {
        get() {
          //console.log(this.valueLine);
          let timeVol = "";
          if (typeof this.valueLine.timeVol != "undefined") {
            timeVol = this.valueLine.timeVol;
          }
          return timeVol;
        },
        set(selected) {
          console.log(selected);
          this.$set(this.valueLine, "timeVol", selected);
        },
      },*/
    /*    fieldAttrInputSpecial() {
        let fieldAttrInput = { max: 60, min: 1, class: "number" };
        if (this.valueLine.timeVol == "volume") {
          fieldAttrInput = {};
        }
        return fieldAttrInput;
      },*/
    type() {
      let type = "number";
      /*if (this.valueLine.timeVol == "volume") {
          type = "text";
        }*/
      return type;
    },
    name() {
      let name = "Test Duration in Seconds";
      /* if (this.valueLine.timeVol == "volume") {
          name = "Volume";
        }*/
      return name;
    },
    duration: {
      get() {
        let duration = this.valueLine.duration;
        if (isNaN(duration)) {
          duration = "10";
        }
        return duration;
      },
      set(selected) {
        console.log(selected);
        this.$set(this.valueLine, "duration", selected);
      },
    },
    templateContentRow() {
      let templateContentRow = {
        fields: {
          congestionHighTimeLine: {
            type: "line",
            fields: {
              /*   omit: {
                  type: "number",
                  fieldAttrInput: { class: "" },
                  colAttrs: { class: "" },
                  name: "Omit the First n Seconds",
                },*/
              parallel: {
                type: "number",
                fieldAttrInput: { class: "" },
                colAttrs: { style: "max-width:275px", class: "" },
                name: "Number of Parallel Streams",
              },
            },
          },
        },
        contentID: this.result.contentID,

        disableAllInputs: this.result.disableAllInputs,
      };
      return templateContentRow;
    },
    /*      evalValue: {
        get() {
          let evalValue = { };
          // console.log(this.localStoredStep);
          if (
            typeof this.valueLine!= "undefined"
          ) {
            evalValue = this.valueLine;
          }
          return evalValue;
        },
        set(newVal) {
           this.updateResponseKey("time", newVal);
        },
      },*/
    time: {
      get() {
        let time = "";
        console.log(this.valueLine);
        if (
          typeof this.valueLine != "undefined" &&
          typeof this.valueLine.time != "undefined"
        ) {
          time = this.valueLine.time;
        }
        return time;
      },
      set(newVal) {
        this.$set(this.valueLine, "time", newVal);
        //this.updateResponseKey("time", this.evalValue);
      },
    },
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes);
    },
    templateContent() {
      let templateContent = {
        fields: {
          uplinkDownlinkSwitch: {
            type: "switchGroup",
            name: "Test Direction",
            fields: {
              uplink: {
                name: "Uplink",
              },
              downlink: {
                name: "Downlink",
              },
            },
          },
          /*   time: {
              type: "number",
              name: "Speedtest in seconds",
              fieldAttrInput: {
                class: "mr-3"
              },
            },*/
        },
        contentID: this.result.contentID,
        disableAllInputs: this.result.disableAllInputs,
      };
      return templateContent;
    },
  },
  methods: {
    /*      updateResponseKey(index, newVal) {
        //console.log( "---",index, newVal);
        this.$set(this.localStoredStep["parameters"], index,newVal  );
        this.$emit("stored-value-changed", this.localStoredStep);
      },*/
    updateFieldValue(index, value) {
      console.log("--->", index, value);
      this.$set(this.valueLine, index, value);
      this.$emit("stored-value-changed", this.valueLine);
    },
  },
};
</script>